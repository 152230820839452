import { formateDate } from './util';

export const formatDate = (time) => {
    if (typeof(time) === 'string') {
        if (/^\d+$/.test(time)) {
            time = parseInt(time)
        } else {
            return time
        }
    }
    return formateDate(time*1000)
}