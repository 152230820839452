import Vue from "vue";

// import {
//   ElContainer,
//   ElMain,
//   ElForm,
//   ElFormItem,
//   ElInput,
//   ElSelect,
//   ElOption,
//   ElButton,
//   ElRow,
//   ElCol,
//   ElTable,
//   ElTableColumn,
//   ElTag,
//   ElRadio
// } from 'element-ui'
// // import './assets/styles/element-variables.scss'

// Vue.use(ElContainer)
// Vue.use(ElMain)
// Vue.use(ElForm)
// Vue.use(ElFormItem)
// Vue.use(ElInput)
// Vue.use(ElSelect)
// Vue.use(ElOption)
// Vue.use(ElButton)
// Vue.use(ElRow)
// Vue.use(ElCol)
// Vue.use(ElTable)
// Vue.use(ElTableColumn)
// Vue.use(ElTag)
// Vue.use(ElRadio)

import Element from 'element-ui'
import '@/css/element/element-variables.scss'

import '@/css/element/index.scss' // global css
import '@/css/element/ruoyi.scss' // ruoyi css

import ViewUI from "view-design";
import VueRouter from "vue-router";
import Routers from "./router.js";

import * as filters from './libs/filters.js'

import { setTitle } from "./libs/util";
import App from "./app.vue";
import "view-design/dist/styles/iview.css";
import "./css/base.less";
import {ajax} from './libs/util';

// 百度地图
import BaiduMap from 'vue-baidu-map'
// 跨域请求支持
import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)

Vue.use(VueRouter);
Vue.use(Element)
Vue.use(ViewUI);
Vue.use({
    install(vue){
        vue.prototype.$ajax = ajax;
        // baidu map ak
        vue.prototype.$bdmapAK = process.env.VUE_APP_BAIDU_MAP_AK
    }
});
Vue.use(BaiduMap, {
  ak: process.env.VUE_APP_BAIDU_MAP_WEB_AK
})

// Register global filter functions
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

//全局配置
window.CONFIG = {
  pageSize: 20, //列表页每页显示多少条
};

// 路由配置
const RouterConfig = {
  mode: "history",
  routes: Routers,
};
const router = new VueRouter(RouterConfig);

router.beforeEach((to, from, next) => {
  ViewUI.LoadingBar.start();
  setTitle(to.meta.title);
  next();
});

router.afterEach(() => {
  ViewUI.LoadingBar.finish();
  window.scrollTo(0, 0);
});

new Vue({
  el: "#app",
  router: router,
  render: (h) => h(App),
});
