const fenceRouters = [{
    name: "绘制起终点",
    path: "/schedule/makefence",
    component: (resolve) => require(["./views/schedule/mapfence.vue"], resolve),
    props: route => ({ name: route.query.name }),
    meta: {
        title: "绘制起终点"
    }
},
{
    name: "起终点管理",
    path: "/schedule/mapfence",
    component: (resolve) => require(["./views/schedule/mapfencelist.vue"], resolve),
    props: route => ({ name: route.query.name }),
    meta: {
        title: "起终点管理"
    }
},
{
    name: "设备线路绑定",
    path: "/schedule/device",
    component: (resolve) => require(["./views/schedule/devicelist.vue"], resolve),
    props: route => ({ device: route.query.device, vehicle: route.query.vehicle }),
    meta: {
        title: "设备线路绑定"
    }
},
{
    name: "运输计划列表",
    path: "/schedule/devicefence",
    component:(resolve) => require(["./views/schedule/devicefencelist.vue"], resolve),
    props: route => ({ device: route.query.device }),
    meta: {
        title: "运输计划列表"
    }
},
{
    name: "生成运单数据",
    path: "/schedule/shipping",
    component:(resolve) => require(["./views/schedule/shippinglist.vue"], resolve),
    props: route => ({ number: route.query.number }),
    meta: {
        title: "生成运单数据"
    }
},
{
    name: "推送消息记录",
    path: "/schedule/pushlist",
    component:(resolve) => require(["./views/schedule/pushlist.vue"], resolve),
    props: route => ({ number: route.query.number }),
    meta: {
        title: "推送消息记录"
    }
},
{
    name: "司机打卡记录",
    path: "/schedule/devicedriver",
    component:(resolve) => require(["./views/schedule/devicedriverlist.vue"], resolve),
    meta: {
        title: "司机打卡记录"
    }
}]

const routers = [{
        path: "/",
        meta: {
            title: "登录-企业服务后台系统",
        },
        component: (resolve) => require(["./views/index.vue"], resolve),
    },
    {
        path: "/check-health",
        meta: {
            title: "健康检查-企业服务后台系统",
        },
        component: (resolve) => require(["./views/check-health.vue"], resolve),
    },
    {
        path: "/main",
        meta: {
            title: "主页面",
        },
        component: (resolve) => require(["./views/main.vue"], resolve),
        children: [{
                path: "/index",
                name: "首页",
                component: (resolve) => require(["./views/index/index.vue"], resolve),
            },
            ...fenceRouters,
            {
                path: "/manage/device-query",
                name: "设备列表",
                component: (resolve) => require(["./views/devices/list.vue"], resolve),
            },
             {
                path: "/manage/device-expire",
                name: "设备过期列表",
                component: (resolve) => require(["./views/devices/expire.vue"], resolve),
            },
            {
                path: '/devices/trail_back',
                name: '轨迹回放',
                component: (resolve) => require(["./views/devices/trail-back.vue"], resolve),
            },
            {
                path: "/monitor",
                name: "监控中心",
                component: (resolve) => require(["./views/monitor/center.vue"], resolve),
            },
            {
                path: "/config",
                name: "企业配置",
                component: (resolve) => require(["./views/config/index.vue"], resolve),
            },
            // {
            //     path: "/set/base-set",
            //     name: "基本设置",
            //     component: (resolve) =>
            //         require(["./views/set/base-set.vue"], resolve),
            // },
            {
                path: "/user/list",
                name: "客户列表",
                component: (resolve) => require(["./views/user/list.vue"], resolve),
            },
            {
                path: "/user/user_group",
                name: "权限管理",
                component: (resolve) => require(["./views/user/user_group"], resolve),
            }
        ],
    },
];
export default routers;