import axios from 'axios';
import Cookies from 'js-cookie';
import Message from 'view-design/src/components/message';

const { VUE_APP_API_HOST, VUE_APP_API_QIYE_HOST } = process.env;

export const host = VUE_APP_API_HOST
export const qiye_host = VUE_APP_API_QIYE_HOST

function convertToExcel(data, name) {
  const filename = `${name}.xls`
    console.log(filename)
    const blob = new Blob(data,{type:"application/vnd.ms-excel;charset=utf-8"})
    const downloadElement = document.createElement('a')
    const href = window.URL.createObjectURL(blob)
    console.log(href)

    downloadElement.href = href
    downloadElement.download = filename
    document.body.appendChild(downloadElement)
    downloadElement.click()
    document.body.removeChild(downloadElement)
    window.URL.revokeObjectURL(href)

  // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //   const blob = new Blob(data,{type:"application/vnd.ms-excel;charset=utf-8"})
  //   window.navigator.msSaveOrOpenBlob(blob, filename)
  // } else {
  //   /* 火狐谷歌的文件下载方式 */
  //   const blob = new Blob(data,{type:"application/vnd.ms-excel;charset=utf-8"})
  //   const downloadElement = document.createElement('a')
  //   const href = window.URL.createObjectURL(blob)
  //   console.log(href)

  //   downloadElement.href = href
  //   downloadElement.download = filename
  //   document.body.appendChild(downloadElement)
  //   downloadElement.click()
  //   document.body.removeChild(downloadElement)
  //   window.URL.revokeObjectURL(href)
  // }
}
function request(type, url, data) {
    data = (data == null || data == "" || typeof(data) == "undefined") ? JSON.stringify({ "date": Date.now() }) : data;
    

    if (url === '/xhk_web' && data.service) {
        url = String(VUE_APP_API_QIYE_HOST) + '/' + data.service

    } else {
        url = String(VUE_APP_API_QIYE_HOST) + url;
    }


    let header = {
        'content-type': 'application/json',
        'token': Cookies.get('token') || '',
    };
    return new Promise((resolve, reject) => {
        axios({
                method: type,
                url: url,
                data: data,
                headers: header,
                timeout: 60000, //超时时间60s
            })
            .then((response) => {
                /*
                 *   response为null表示未登录，拦截器拦截直接跳转到登录界面，不触发回调
                 *   response不为null时，触发回调，正常执行
                 */
                if (!isEmpty(response)) {

                    if (response.data.code == 200) {
                        resolve(response.data.data);
                    } else if (response.data.code == 1001 || response.data.code == 1002 || response.data.code == 401) {
                        Message.error(response.data.message)
                        Cookies.set('token', '', { path: '/' });
                        window.location.href = '/';
                    } else {
                        Message.error(response.data.message || "服务器异常，请重试")
                        reject(response.data.message);
                    }
                }
            })
            .catch((error) => {
                if (error.response) {
                    // 请求已发出，但服务器响应的状态码不在 2xx 范围内
                    Message.error(error.response.data);
                    reject(response.data.message);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    Message.error(error.message);
                    reject(response.data.message);
                }
            });
    })
}

let ajax = {
    Post: function(url, data) {
        return request('post', url, data)
    },
    get: function(url, successfn, errorfn) {
        this.ajaxDo('get', url, '', successfn, errorfn);
    },
    delete: function(url, successfn, errorfn) {
        this.ajaxDo('delete', url, '', successfn, errorfn);
    },
    post: function(url, data, successfn, errorfn) {
        this.ajaxDo('post', url, data, successfn, errorfn);
    },
    put: function(url, data, successfn, errorfn) {
        this.ajaxDo('put', url, data, successfn, errorfn);
    },
    ajaxDo: function(type, url, data, successfn, errorfn) {
        data = (data == null || data == "" || typeof(data) == "undefined") ? JSON.stringify({ "date": Date.now() }) : data;
        errorfn = typeof errorfn === 'function' ? errorfn : function() {

        };

        // if (url === '/xhk_web' && data.service) {
        //     url = String(VUE_APP_API_HOST) + '/' + data.service

        // } else {
        //     url = String(VUE_APP_API_QIYE_HOST) + url;
        // }

        if (url === '/xhk_web' && data.service) {
            url = String(VUE_APP_API_QIYE_HOST) + '/' + data.service

        } else {
            url = String(VUE_APP_API_QIYE_HOST) + url;
        }


        let header = {
            'content-type': 'application/json',
            'token': Cookies.get('token') || '',
        };
        axios({
                method: type,
                url: url,
                data: data,
                headers: header,
                timeout: 60000, //超时时间60s
            })
            .then((response) => {
                /*
                 *   response为null表示未登录，拦截器拦截直接跳转到登录界面，不触发回调
                 *   response不为null时，触发回调，正常执行
                 */
                if (!isEmpty(response)) {
                    if (response.data.code == 0 || response.data.code == 200) {
                        successfn(response.data);
                    } else if (response.data.code == 1001 || response.data.code == 1002 || response.data.code == 401) {
                        Cookies.set('token', '', { path: '/' });
                        window.location.href = '/';
                    } else {
                        errorfn && errorfn(response.data);
                    }
                }
            })
            .catch((error) => {
                if (error.response) {
                    // 请求已发出，但服务器响应的状态码不在 2xx 范围内
                    Message.error(error.response.data);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    Message.error(error.message);
                }
            });

    }
};


let ajaxBlob = {
    Post: function(url, data) {
        return request('post', url, data)
    },
    get: function(url, successfn, errorfn) {
        this.ajaxDo('get', url, '', successfn, errorfn);
    },
    delete: function(url, successfn, errorfn) {
        this.ajaxDo('delete', url, '', successfn, errorfn);
    },
    post: function(url, data, successfn, errorfn) {
        this.ajaxDo('post', url, data, successfn, errorfn);
    },
    put: function(url, data, successfn, errorfn) {
        this.ajaxDo('put', url, data, successfn, errorfn);
    },
    ajaxDo: function(type, url, data, successfn, errorfn) {
        data = (data == null || data == "" || typeof(data) == "undefined") ? JSON.stringify({ "date": Date.now() }) : data;
        errorfn = typeof errorfn === 'function' ? errorfn : function() {

        };

        // if (url === '/xhk_web' && data.service) {
        //     url = String(VUE_APP_API_HOST) + '/' + data.service

        // } else {
        //     url = String(VUE_APP_API_QIYE_HOST) + url;
        // }

        if (url === '/xhk_web' && data.service) {
            url = String(VUE_APP_API_QIYE_HOST) + '/' + data.service

        } else {
            url = String(VUE_APP_API_QIYE_HOST) + url;
        }


        let header = {
            'content-type': 'application/json',
            'token': Cookies.get('token') || '',
        };
        axios({
                method: type,
                url: url,
                data: data,
                headers: header,
                timeout: 60000, //超时时间60s
                responseType: 'blob',

            })
            .then((response) => {
                /*
                 *   response为null表示未登录，拦截器拦截直接跳转到登录界面，不触发回调
                 *   response不为null时，触发回调，正常执行
                 */
                    successfn(response || '')
                 /*

                if (!isEmpty(response)) {

                    if (response.data.code == 200) {
                       successfn(response || '')
                    } else if (response.data.code == 1001 || response.data.code == 1002 || response.data.code == 401) {
                        Cookies.set('token', '', { path: '/' });
                        window.location.href = '/';
                    } else {
                        errorfn && errorfn(response.data);
                    }
                }
                */
            })
            .catch((error) => {
                if (error.response) {
                    // 请求已发出，但服务器响应的状态码不在 2xx 范围内
                    Message.error(error.response.data);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    Message.error(error.message);
                }
            });

    }
};

function setTitle(title) {
    title = title ? title : '企业服务后台系统';
    window.document.title = title;
}

function formateTime(value) {
    if (!value) {
        return '';
    }
    var y = value.substr(0, 4);
    var m = value.substr(4, 2);
    var d = value.substr(6, 2);
    var h = value.substr(8, 2);
    var mm = value.substr(10, 2);
    var s = value.substr(12, 2);
    return y + '-' + m + '-' + d + ' ' + h + ':' + mm + ':' + s;
}

//取任意天数日期
function getDayBefore(day) {
    var date = new Date(),
        timestamp, newDate, M, D;
    if (!(date instanceof Date)) {
        date = new Date(date.replace(/-/g, '/'));
    }
    timestamp = date.getTime();
    newDate = new Date(timestamp - day * 24 * 3600 * 1000);
    M = newDate.getMonth() + 1;
    D = newDate.getDate();
    if (M < 10) M = "0" + M;
    if (D < 10) D = "0" + D;
    // return [[newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate()].join('-'), [newDate.getHours(), newDate.getMinutes(), newDate.getSeconds()].join(':')].join(' ');
    return [newDate.getFullYear(), M, D].join('-');
}

function getVerifyStatus(type) {
    let obj = {
        '0': '通过',
        '1': '不通过',
        '2': '待审核'
    }
    return obj[type] || '';
}

function getPlatformStatus(type) {
    let obj = {
        '0': '网络货运司机安卓',
        '1': '司机IOS',
        '2': '司机安卓'
    }
    return obj[type] || '';
}

const levelMap = {
    100: '网络货运普通版',
    101: '网络货运高级版'
};
const companyState = {
    0: '未开通',
    1: '已开通',
};

function formateDate(value, dateType) {
    if (!value) {
        return '';
    }
    var date = new Date(value);

    var Y = date.getFullYear();
    var M = date.getMonth() + 1;
    M = M < 10 ? '0' + M : M;
    var D = date.getDate();
    D = D < 10 ? '0' + D : D;
    var h = date.getHours();
    var m = date.getMinutes();
    var s = date.getSeconds();
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;

    if (dateType === '1') {
        return Y + '-' + M + '-' + D;
    } else if (dateType === '2') {
        return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s;
    } else {
        return Y + '-' + M + '-' + D + ' ' + h + ':' + m;
    }
}

function deepClone(obj) {
    if (obj === null || typeof obj !== "object") {
        return obj;
    }
    let newObj = obj.constructor === Array ? [] : {}; //开辟一块新的内存空间
    for (let i in obj) {
        newObj[i] = deepClone(obj[i]); //通过递归实现深层的复制
    }
    return newObj;
}

function trim(str) { //删除左右两端的空格
    return str.replace(/(^\s*)|(\s*$)/g, "");
}

function isEmpty(str) {
    if (typeof str === 'undefined' || str === null) {
        return true;
    }
    if (typeof str === 'string' && str.length <= 0) {
        return true;
    }
    return /^[ ]+$/.test(str);
}

function encode(value) { // 打印模板编码
    return encodeURIComponent(value).replace(/'/g, "%27").replace(/"/g, "%22");
}

function decode(value) { // 打印模板解码
    return decodeURIComponent(value.replace(/\+/g, " "));
}

function checkAuth(auth) {
    // 如果登录账户是admin，忽视权限
    if (Cookies.get('name') == 'admin') {
        return true;
    }
    const auths = (sessionStorage.authString || '').split(','); //权限列表
    let own = false; //如果没有传入检查的权限,则校验不通过
    if (auth) {
        let match = auth.split('||');
        match.forEach((str) => {
            str = trim(str);
            let o = auths.indexOf(str) >= 0;
            own = own || o;
        })
    }
    return own;
}

function getTextLength(str) {
    return str.replace(/[^\x00-\xff]/g, "xx").length;
}

/*
 *   height： 为浏览器视窗高度
 *   offset_table：表格顶部距离父视图顶部的高度
 *   pagebox_height： 底部合计+页码高度
 *   62：顶部个人信息30 + 面包屑32 高度，暂时写死，后期UI优化之后再改
 *   30：pagebox底部留白
 *
 *   params:
 *   flag: 微调15个单位
 *   tabPane: 有tab，需要多减去tab的高度，37px
 * */
function getTableHeight(flag, tabPane) {
    let height = window.innerHeight; // 视窗高度
    let searchHeight = document.getElementById('search_top').offsetHeight;
    let offset_table = document.getElementById('table').offsetTop; //表格距顶部的距离
    let pagebox = document.getElementById('page-box');

    let result = 0;
    let pagebox_height = pagebox.offsetHeight;
    result = height - searchHeight - pagebox_height - 64;
    // console.log(height,searchHeight,pagebox_height)
    /*
     * 备注： 表格有表头，表头和单元格的高度都为32px,因此，这里的result的高度，必需要大于2个单元格的高度，否则
     * 用户无法完整的看到表格数据。这里默认result必需大于100，否则显示完整的表格；
     * */
    return result > 100 ? result : 100;
}

function getFrameHeight() {
    let height = window.innerHeight;
    return height - 69;
}

function addOnlineJs(src, callback) {
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.onload = script.onreadystatechange = function() {
        if ((!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete')) {
            callback && callback();
            // Handle memory leak in IE
            script.onload = script.onreadystatechange = null;
            if (head && script.parentNode) {
                head.removeChild(script);
            }
        }
    };
    head.insertBefore(script, head.firstChild);
}

function checkNumber(input) {
    var re = /^\+?[1-9][0-9]*$/;
    if (!re.test(input)) {
        return false;
    }
    return true;
}

//按照id添加元素
export function addDomForId(id) {
    let element = document.querySelector('#' + id) || document.createElement('div');
    element.id = id;
    document.body.appendChild(element);
}

//默认值设置
export function checkValueContainDefault(value, defaultValue) {
    return value == null ? defaultValue : value;
}

export {
    setTitle,
    formateDate,
    formateTime,
    deepClone,
    isEmpty,
    trim,
    encode,
    decode,
    checkAuth,
    getTextLength,
    getTableHeight,
    ajax,
    ajaxBlob,
    addOnlineJs,
    checkNumber,
    getFrameHeight,
    getVerifyStatus,
    getDayBefore,
    getPlatformStatus,
    levelMap,
    convertToExcel,
    companyState
}